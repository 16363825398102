<template>
  <!--begin::Advance Table Widget 9-->
  <div>


    <!--begin::Body-->
    <div class="pt-0 pb-3">
      <div class="d-md-flex justify-content-between align-items-center mb-4">
        <div class="w-100 mr-md-10 mb-md-0 mb-4">

          <b-form-group
            id="input-group-created_from"
            :label="$t('PAGES.RENEWS.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>

          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            :placeholder="$t('BOOK.SEARCH_VOUCHER')"
          ></b-form-input>
        </div>
        <div class="d-sm-flex justify-content-end align-items-center">
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 150, 200]"
            v-model="per_page"
          />

          <b-pagination
            class="ml-auto mb-0"
            v-model="currentPage"
            :total-rows="books.length"
            :per-page="per_page"
            aria-controls="search-result-table"
          ></b-pagination>
        </div>
      </div>
      <!--begin::Table-->
      <div class="table-responsive">
        <b-table
          id="search-result-table"
          class="mt-3 cursor mh-100 table-striped"
          :filter="filters"
          :filter-function="customFilter"
          :items="books"
          :fields="headers"
          :per-page="per_page"
          :current-page="currentPage"
          head-variant="light"
          sticky-header
        >
          <template #cell(voucherNumber)="books">
            <span>{{
              books.item.external_id
                ? books.item.voucher_series + books.item.external_id
                : 'Ej bokförd'
            }}</span>
          </template>
          <template #cell(status)="books">
            <span>{{ getStatus(books.item.status) }}</span>
          </template>
          <template #cell(action)="books">
            <div class="text-right">
              <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="editBook(books.item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                </span>
              </a>
              <a
                class="btn btn-icon btn-light btn-sm mx-2"
                v-if="!books.item.external_id"
                @click.prevent="deleteBook(books.item.book_id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                </span>
              </a>
            </div>
          </template>
        </b-table>

      </div>

    </div>

  </div>

</template>
<style lang="scss" scoped>

</style>
<script>

import BookTableRow from '@/view/pages/ml/book/BookTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'book-table',
  props: {
    showError: {
      type: Boolean,
      default: true
    },
    books: {
      type: Array,
      default: []
    },
    currentCompanyId: {
      type: String,
      default: ''
    }
  },
  emits: ['createBookClicked', 'selectBookClicked', 'deleteBookClicked'],
  mixins: [ toasts ],
  components: {
    BookTableRow,
  },
  mounted() {
    this.headers = this.headers.filter(item => (item.key === 'error' && this.showError) || item.key !== 'error');
  },
  methods: {
    customFilter(row, filters) {

      const { created_from, text } = filters;

      if (created_from && !row.created_at.includes(created_from)) {
        return false;
      }

      if (text && !row.external_id.includes(text) && !row.message.includes(text) && !(row.book_id+'').includes(text)) {
        return false;
      }

      return true;
    },
    createBook() {
      this.$emit('createBookClicked');
    },
    editBook(book) {
      this.$emit('selectBookClicked', book);
    },
    deleteBook(bookId) {
      this.$emit('deleteBookClicked', bookId);
    },
    getStatus(status) {
      const BOOK_STATUS = {
        DO_NOT_SYNC_FORTNOX: 'DO_NOT_SYNC_FORTNOX',
        TO_BE_SYNCED_FORTNOX: 'TO_BE_SYNCED_FORTNOX',
        PENDING_FORNOX: 'PENDING_FORNOX',
        IN_FORTNOX: 'IN_FORTNOX',
        ERROR: 'ERROR'
      };

      switch (status) {
        case BOOK_STATUS.DO_NOT_SYNC_FORTNOX:
          return 'Fel uppstod';
        case BOOK_STATUS.TO_BE_SYNCED_FORTNOX:
          return 'Väntar';
        case BOOK_STATUS.IN_FORTNOX:
          return 'Bokförd';
        case BOOK_STATUS.ERROR:
          return this.$t('COMMON.ERROR');
        default:
          return 'Okänd';
      }
    },

  },
  data() {
    return {
      all_selected: false,
      filters: {
        text: '',
        created_from: null
      },
      per_page: 100,
      currentPage: 1,
      headers: [
        {
          key: 'external_id',
          label: this.$t('BOOK.VOUCHER_NUMBER') ,
          thClass: 'pl-7 align-middle w-60px',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'created_at',
          label: this.$t('BOOK.CREATED_AT') ,
          thClass: 'w-160px align-middle',
          tdClass: 'align-middle',
          sortable: true
        },
        {
          key: 'message',
          label: this.$t('BOOK.MESSAGE') ,
          thClass: 'w-160px align-middle',
          tdClass: 'align-middle',
          sortable: true
        },
        {
          key: 'status',
          label: this.$t('BOOK.STATUS') ,
          thClass: 'w-160px align-middle',
          tdClass: 'align-middle',
          sortable: true
        },
        {
          key: 'error',
          label: this.$t('BOOK.ERROR') ,
          thClass: 'w-160px align-middle',
          tdClass: 'align-middle',
          sortable: true
        },
        { key: 'action', label: '' }
      ],
      list: []
    };
  }
};
</script>
