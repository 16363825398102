<template>
  <b-modal size="lg" ref="modal" title="Dinterofiler" v-model="showUploadModal" hide-footer>
    <b-row v-if="showError">
      <b-col cols="12">
        <b-alert variant="danger" show>{{ errorText }}</b-alert>
      </b-col>
    </b-row>

    <div v-if="!files_uploaded" class="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text">En CSV fil måste laddas upp med Dintero transaktioner. Underlag hos Fortnox kommer att
        genereras av memlist.</div>
    </div>


    <b-row>
      <b-col cols="6">
        <v-card :color="csv_style" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline" text="title" style="width: 260px; overflow: hidden;"></v-card-title>

              <v-card-subtitle v-text="csv_filename" style="color: #000000; width: 260px; overflow: hidden;">
              </v-card-subtitle>
              <b-button v-if="!csv_uploaded" style="margin-left: 10px;" variant="primary"
                @click.prevent="uploadCSVClicked">Ladda upp</b-button>
              <b-button v-if="csv_uploaded" style="margin-left: 10px;" variant="danger"
                @click.prevent="deleteCSVClicked">Radera</b-button>

            </div>

            <v-avatar class="ma-3 my-3" size="96" tile>
              <v-img src="/assets/images/csv_file.png"></v-img>
            </v-avatar>

          </div>
        </v-card>

      </b-col>
      <!--<b-col cols="6">
            <v-card
                :color="pdf_style"
                dark
                >
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                    <v-card-title
                        class="headline"
                        text="title"
                        style="width: 260px; overflow: hidden;"
                    ></v-card-title>

                    <v-card-subtitle v-text="pdf_filename" style="color: #000000; width: 260px; overflow: hidden;">
                    </v-card-subtitle>
                    <b-button v-if="!pdf_uploaded" style="margin-left: 10px;" variant="primary" @click.prevent="uploadPDFClicked">Ladda upp</b-button>
                    <b-button v-if="pdf_uploaded" style="margin-left: 10px;" variant="danger" @click.prevent="deletePDFClicked">Radera</b-button>
                    </div>

                    <v-avatar
                    class="ma-3 my-3"
                    size="98"
                    tile
                    >
                    <v-img src="/assets/images/pdf_file.png"></v-img>
                    </v-avatar>

                </div>
            </v-card>
        </b-col>-->
    </b-row>

    <b-row>
      <b-col cols="12">
        <div v-if="files_uploaded" class="alert alert-custom alert-notice alert-light-success fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">När du klickar på "Bokför" så kommer bifogade fil att läsas igenom och laddas upp mot
            Fortnox och verifikat skapas.</div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-6">
      <b-col lg="6">
        <b-button variant="outline-success" :disabled="!files_uploaded" @click="createFortnoxVouchers">
          {{ $t('BOOK.BOOKIT') }}
        </b-button>
      </b-col>
      <b-col lg="6">
        <b-button variant="outline-primary" @click="cancel">
          {{ $t('COMMON.CANCEL') }}
        </b-button>
      </b-col>
    </b-row>
    <input hidden type="file" id="csvFile" ref="csvFile" accept=".csv" v-on:change="handleCSVFileUpload()" />
    <input hidden type="file" id="pdfFile" ref="pdfFile" accept="application/pdf" v-on:change="handlePDFFileUpload()" />
  </b-modal>
</template>
<style lang="scss" scoped></style>
<script>
import axios from 'axios';

import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'DinteroSettlementUploadModal',
  components: {
    Confirm
  },
  mixins: [ toasts ],
  data() {
    return {
      files_uploaded: false,
      pdf_uploaded: false,
      csv_uploaded: false,
      csv_style: "#eeeeee",
      pdf_style: "#eeeeee",
      ok_style: "#eeffee",
      not_ready_style: "#eeeeee",
      csv_filename: '',
      pdf_filename: '',
      showUploadModal: false,
      showError: false,
      errorText: 'Ett fel uppstod',
      csv_file: null,
      pdf_file: null,
      selectedFileType: null,
    };
  },
  props: {

  },
  emits: ['cancel'],
  watch: {
  },
  computed: {

  },
  async mounted() {

  },
  methods: {
    createFortnoxVouchers() {
      this.postDinteroSettlementBooks(this.csv_file.file_id);
    },
    uploadPDFClicked() {
      this.selectedFileType = 'pdf';

      this.$refs.pdfFile.click();
    },
    uploadCSVClicked() {
      this.selectedFileType = 'csv';

      this.$refs.csvFile.click();
    },
    deletePDFClicked() {
      this.pdf_uploaded = false;
      this.pdf_file = null;
      this.pdf_filename = '';
      this.pdf_style = this.not_ready_style;
      this.files_uploaded = false;
    },
    deleteCSVClicked() {
      this.csv_uploaded = false;
      this.csv_file = null;
      this.csv_filename = '';
      this.csv_style = this.not_ready_style;
      this.files_uploaded = false;
    },
    handlePDFFileUpload() {
      this.file = this.$refs.pdfFile.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();

      this.file = this.$refs.pdfFile.value = null;
    },
    handleCSVFileUpload() {
      this.file = this.$refs.csvFile.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();

      this.$refs.csvFile.value = null;
    },
    selectFileForUpload() {
      this.$refs.file.click();
    },
    uploadFile() {
      const loader = this.$loading.show();

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_OTHER');
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          loader && loader.hide();
          if (this.selectedFileType === 'csv') {
            this.csv_file = res.data.fileobjs[0];
            this.csv_filename = this.csv_file.name;
            this.csv_uploaded = true;
            this.csv_style = this.ok_style;
          }
          else {
            this.pdf_file = res.data.fileobjs[0];
            this.pdf_filename = this.pdf_file.name;
            this.pdf_uploaded = true;
            this.pdf_style = this.ok_style;
          }

          this.files_uploaded = this.csv_uploaded;

          this.toastr('success', this.$t('COMMON.OK'), 'Filen laddades upp');
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda upp fil');
        });
    },
    postDinteroSettlementBooks(csv_file_id) {
      const loader = this.$loading.show();
      axios
        .post(`/fortnox/dintero_settlement_file`, {
          csv_file_id: csv_file_id
        })
        .then(res => {
          loader && loader.hide();
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), 'Bokföring skapad');
            this.close();
          }
          else {
            console.error(res.status);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Okänt fel');
          }
        })
        .catch(err => {
          loader && loader.hide();
          console.error('/fortnox/dintero_settlement_file error', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa bokföring');
        });
    },
    show() {
      this.showUploadModal = true;
    },
    close() {
      this.showUploadModal = false;
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('cancel');
    },

  }
};
</script>
<style scoped></style>

