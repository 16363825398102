<template>
  <div class="px-5" id="page-done-books">

    <BookTable
      :books="local_books"
      :showError="false"
      type="done"
      @selectBookClicked="selectBook"
      @deleteBookClicked="deleteBook"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BookTable from '@/view/pages/ml/book/BookTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'booksdone',
  props: ['books'],
  emits: ['selectBook'],
  components: {
    BookTable
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentBookId', 'currentPeriodId'])
  },
  mixins: [ toasts ],
  mounted() {
    if (this.books) {
      this.local_books = { ...this.books };
    }
  },
  data() {
    return {
      loaded: false,
      local_books: [],
    };
  },
  watch: {
    books: {
      deep: true,
      handler(val) {
        this.local_books = val;
      }
    },
  },
  methods: {
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      this.loadBooks();
    },
    deleteBook(book_id) {
      axios
        .delete(`/book?book_id=${book_id}&company_id=${this.currentCompanyId}`)
        .then(res => {
          this.local_books = this.books.filter(item => item.book_id !== book_id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), 'Verifikatet togs bort');

          this.loadBooks();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort verifikatet');
        });
    },
    selectBook(book) {
      this.$emit('selectBook', book);
    },
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },

  }
};
</script>
