<template>
  <tr>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
          <b-form-group>
            <div class="d-flex align-items-center">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input type="checkbox" name="" v-model="local_book.selected"  @click="local_book.selected = !local_book.selected" />
                <span></span>
                </label>
                <span class="ml-3 cursor-pointer"></span>
            </div>
          </b-form-group>
      </span>
    </td>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ local_book.book_id }}</span>
    </td>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ local_book.external_id }}</span>
    </td>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ local_book.created_at }}</span>
    </td>
  </tr>
</template>
<style lang="scss" scoped>

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'book-table-row',
  props: ['book'],
  emits: ['selectPeriodClicked'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      local_book: {},
    };
  },
  mounted() {
    if (this.book) {
      this.local_book = { ...this.book }
    }
  },
  watch: {
    book: {
      deep: true,
      handle(val) {
        this.local_book = { ...val }
      }
    }
  },
  methods: {
    selectBookClicked(booking_id) {
      this.$emit('selectPeriodClicked', period_id);
    },
  }
};
</script>
